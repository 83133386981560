<template>
  <div class="d-flex align-items-center justify-content-center">
    <div class="pay--countdown">
      <div class="top--part">{{ $t('order.payBefore') }}</div>
      <vue-countdown
        class="countdown"
        :time="paymentDueDiff"
        :transform="transformSlotProps"
        :interval="1000"
        v-slot="{ hours, minutes, seconds }"
      >
        {{ hours }}:{{ minutes }}:{{ seconds }}
      </vue-countdown>
    </div>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {
  name: 'countdown',
  props: ['paymentDueDiff'],
  components: {
    VueCountdown,
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {};

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value);
      });

      return formattedProps;
    },
  },
};
</script>

<style scoped></style>
