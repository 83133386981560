<template>
  <div class="panel--description">
    <div class="container">
      <b-card :title="$t('booking.guide.payment') + paymentMethodName" class="checkout--card">
        <div class="text-center">
          <div class="gopay--qr" v-if="paymentMethodName.toLowerCase() === 'gopay'">
            <div class="qr--mobile" v-show="!$device.isDesktop">
              <div @click="redirectClicked" class="btn btn-primary btn-block">
                {{ $t('booking.payWithGopay') }}
              </div>
            </div>
            <div class="qr--desktop" v-show="$device.isDesktop || tappedRedirect">
              <div class="qr--title" v-show="tappedRedirect">
                {{ $t('booking.scanCode') }}
              </div>
              <div class="qr--title" v-show="!tappedRedirect">{{ $t('booking.scanCodeM') }}</div>
              <img class="qr--img" :src="actions.qr_url" />
            </div>
          </div>
          <div v-if="virtualAccountNumber">
            <div
              id="copyToClipboardVa"
              @click="copyToClipboardVa(virtualAccountNumber)"
              href="javascript:"
              class="va--number"
            >
              <div>{{ virtualAccountNumber }}</div>
              <img src="@/assets/img/icons/copy.png" />
            </div>
            <b-popover :show="copyVaSucceeded" target="copyToClipboardVa" title="">
              {{ $t('general.success') }} <strong> {{ $t('general.clipboard') }}</strong>
            </b-popover>
          </div>
          <div class="payment--value">
            <div class="top--part">{{ $t('booking.paymentValue') }}</div>
            <div class="bottom--part" id="finalPrice">
              {{ $n(price, 'currency', 'id-ID') }}
            </div>
            <a
              class="text-helper"
              id="copyToClipboard"
              @click="copyToClipboard(price)"
              href="javascript:"
            >
              {{ $t('booking.copyValue') }}
            </a>
            <b-popover :show="copySucceeded" target="copyToClipboard" title="">
              {{ $t('general.success') }} <strong> {{ $t('general.clipboard') }}</strong>
            </b-popover>
          </div>
          <div class="order--number">
            <div class="top--part">{{ $t('booking.orderNo') }}</div>
            <div class="bottom--part">{{ orderNumber }}</div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'panelDescription',
  // props: ['bookingOrder'],
  props: [
    'paymentMethodName',
    'price',
    'virtualAccountNumber',
    'viewMybookingUrl',
    'orderNumber',
    'actions',
  ],
  data: () => ({
    copySucceeded: false,
    copyVaSucceeded: false,
    tappedRedirect: false,
  }),
  methods: {
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopy(true);
    },
    async handleCopy(status) {
      this.copySucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copySucceeded = false;
      }, 2000);
    },
    copyToClipboardVa(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyVa(true);
    },
    async handleCopyVa(status) {
      this.copyVaSucceeded = status;
      let self = this;
      setTimeout(() => {
        self.copyVaSucceeded = false;
      }, 2000);
    },
    redirectClicked() {
      this.tappedRedirect = true;
      if (this.actions) {
        window.open(this.actions.deeplink_redirect, '_blank');
      }
    },
  },
};
</script>

<style scoped></style>
